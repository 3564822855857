export const table = {
  label:"Transaction Report",
  exportOpt:{

    sheetName:"Transaction Report",
    fileName:"Transaction Report"
  },

  clmns:[

    {
      'id': 'num',
      'visible':true,
      'type':'number'
      //'value': function($data) {    return $data->numLink();   },
    },
    {
      'id' : 'linenum',
      'visible':true,
      'type':'number'
    },
    {
      'id' : 'type',
      'visible':true,

      //'filter' => app\models\TransactionType::getList(),

      //'value' => function ($data) {
      //  if ($data->ttype != null)
      //    return Yii::t("app", $data->ttype->name);
      //  return $data->type;
      //},
    },
    {
      'id' : 'account_id',
      'visible':true,
      'type':'number'

    },

    /*
    [
      'id' => 'account_type',
      'filter' => \app\models\Acctype::tlist(),

      'value' => function ($data) {
          return \app\models\Acctype::getName($data->account_type);
      },
      'visible' =>false,
    ],

    [
      'id' => 'oppt_account_type',
      'filter' => \app\models\Acctype::tlist(),

      'value' => function ($data) {
          return \app\models\Acctype::getName($data->oppt_account_type);
      },
      'visible' =>false,
    ],
    [
      'id' : 'account_name',
      'visible':true,

      'format' => 'raw',
      'value' => function ($data) {
          return $data->accountLink();
      },
    ],
    [
      'id' => 'oppt_account_id',
      'format' => 'raw',
      'visible' =>false,

      'value' => function ($data) {
          return $data->opptAccountLink();
      },
    ],
    [
      'id' : 'refnum1',
      'visible':true,

      'format' => 'raw',
      'value' => function ($data) {
          return $data->refnumDocsLink();
      },
    ],*/

    {
      'id' : 'refnum2',
      'visible':true,

    },
    {
      'id' : 'details',
      'visible':true,

    },
    {
      'id' : 'valuedate',
      'type':'date',
      'visible':true,


    },
    {
      'id' : 'reg_date',
      'type':'date',
      'visible':true,


    },
    {
      'id' : 'ref_date',
      'type':'date',
      'visible':true,


    },
    /*
    [
      'attribute' => 'sum',
      'visible' =>false,
      'value' => function($data) {
        return \app\helpers\Linet3Helper::asCur($data->sum, $data->currency_id);
      },
      'format' => 'raw',
    ],
    [
      'format' => 'raw',
      'attribute' => 'debit',
      'visible':true,

      'value' => function($data) {
        return \app\helpers\Linet3Helper::numCell($data->debit, -1);
      },
    ],
    [
      'attribute' => 'credit',
      'visible':true,

      'value' => function($data) {
        return \app\helpers\Linet3Helper::numCell($data->credit, 1);
      },
      'format' => 'raw',
    ],
    [
      'attribute' => 'leadsum',
      'visible':true,

      'header' => Yii::t('app', 'Actions'),
      'filter' => '',
      'headerOptions' => ['class' => 'skip-export'],
      'contentOptions' => ['class' => 'skip-export'],
      'group' => true,
      'format' => 'raw',
      'value' => function ($data ) {
        return $data->stornoBtn();
      },
    ]
    */







  ]

}
