export const table = {

  label:"Item Unit",
  exportOpt:{

    sheetName:"Item Unit",
    fileName:"Item Unit"
  },

  clmns:[

    {
      'id': 'id',
      'visible':true,
    },
    {
      'id': 'name',
      'visible':true,
    },

    {
      'id': 'precision',
      'visible':true,
    }

  ]

}
