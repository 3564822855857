import React,{Suspense,Fragment} from 'react';





import Hidden from '@material-ui/core/Hidden';


import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';


import packageInfo from './metadata.json';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';










//import loadable from "@loadable/component";
import { useDispatch } from 'react-redux'

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from "./components/PrivateRoute";

import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';


import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';



import MenuIcon from '@material-ui/icons/Menu';

import IconButton from '@material-ui/core/IconButton';

import './App.css';
import  MainMenu  from './components/menus/MainMenu';
import  UserMenu  from './components/menus/UserMenu';
import  SettingsMenu  from './components/menus/SettingsMenu';


import { loadCSS } from 'fg-loadcss/src/loadCSS';

import Avatar from '@material-ui/core/Avatar';

import { makeStyles } from '@material-ui/core/styles';

import { useAuth0 } from "./react-auth0-spa";

import { ModelRequester } from './actions/modelActions';
import {translationsObject} from './reducers/translationsObject.js';
import { loadTranslations, setLocale } from 'react-redux-i18n';

import {mergeDeep} from './helpers/mergeDeep'
import {linCompany} from './helpers/linCompany'

import { Translate } from 'react-redux-i18n';

const Board = React.lazy(() => import('./components/dashboard/Board'));
const FuncAdminTable = React.lazy(() => import('./components/tables/AdminTable'));
const AgTable = React.lazy(() => import('./components/tables/AgTable'));

const DynaForm = React.lazy(() => import('./components/form/DynaForm'));


const LinFrame = React.lazy(() => import('./components/LinFrame'));
const CompanyAdmin = React.lazy(() => import('./components/company/CompanyAdmin'));
//import  Board  from './components/dashboard/Board';

const routes = [
  //will be..{(this.props.login.loggin?<Route component={NoMatchLogin} />:"")}
  {
    path: "/companywizard/:show",
    main:(path) =><div />//<CompanyCreateWizard params={path.match.params} />
  },
  {
    path: "/companywizard",
    main:(path) =><div />//<CompanyCreateWizard params={{show:'go'}} />
  },
  {
    path: "/reports/detaila",
    main:(path) =><Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><FuncAdminTable model={"docdetailes"} /></Suspense>
  },









  {
    path: "/reports/detail",
    main:(path) =><Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><AgTable model={"docdetailes"} /></Suspense>
  },
  {
    path: "/reports/journal",
    main:(path) =><Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><AgTable model={"transaction"} /></Suspense>
  },

  {
    path: "/acctype/admin",
    main:(path) =><Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><AgTable model={"acctype"} /></Suspense>
  },
  {
    path: "/acccat/admin",
    main:(path) =><Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><AgTable model={"acccat"} /></Suspense>
  },
  {
    path: "/doctype/admin",
    main:(path) =><Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><AgTable model={"doctype"} /></Suspense>
  },
  {
    path: "/itemcategory/admin",
    main:(path) =><Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><AgTable model={"itemcategory"} /></Suspense>
  },
  {
    path: "/itemunit/admin",
    main:(path) =><Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><AgTable model={"itemunit"} /></Suspense>
  },



  /*
inventory/admin


mailtemplate/admin
  smstemplate/admin
  payment/admin
reports/owe
reports/cheque
reports/stock
reports/stockaction

linet3extend/itemlimit/admin


  mutex/type/admin
  mutex/ruler/admin
  mutex/rulerunit/admin
  mutex/category/admin
  docs/admin
  item/admin
  */
  {
    path: "/company/index",
    main:(path) =><Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><CompanyAdmin /></Suspense>
  },
  {
    path: "/dashboard",
    main:(path) =><Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><Board params={{show:'go'}} /></Suspense>
  },
  {
    path: "/settings/dashboard",
    main:(path) =><Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><Board params={{show:'go'}} /></Suspense>
  },

//,""
{
  path: "/docs/duplicate/:doctype/:source",
  main:(path) => <Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><DynaForm model={"doc"} path={path} /></Suspense>
},
{
  path: "/nextdocs/duplicate/:doctype/:source",
  main:(path) => <Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><DynaForm model={"doc"} path={path} /></Suspense>
},
{
    path: "/docs/duplicate?id:source&type=:doctype",
    main:(path) => <Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><DynaForm model={"doc"} path={path} /></Suspense>
  },




  {
    path: "/item/create",
    main:(path) => <Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><DynaForm model={"item"} path={path} /></Suspense>
  },
  {
    path: "/item/update/:id",
    main:(path) => <Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><DynaForm model={"item"} path={path} /></Suspense>
  },

  {
    path: "/accounts/create/:type",
    main:(path) => <Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><DynaForm model={"account"} path={path} /></Suspense>
  },
  {
    path: "/accounts/update/:id",
    main:(path) => <Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><DynaForm model={"account"} path={path} /></Suspense>
  },



  {
    path: "/docs/create/:doctype",
    main:(path) => <Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><DynaForm model={"doc"} path={path} /></Suspense>
  },
  {
    path: "/docs/view/:id",
    main:(path) => <Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><DynaForm model={"docview"} path={path} /></Suspense>
  },
  {
    path: "/docs/authorize",
    main:(path) => <Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><DynaForm model={"docsign"} path={path} /></Suspense>
  },




  {
    path: "/newdocs/update/:id",
    main:(path) =><Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}><DynaForm model={"doc"} path={path} /></Suspense>
  },

  //{
  //  path: "/:model/create",
  //  main:(path) => <Card style={{ padding: '1rem' }}><DynaForm model={path.match.params.model} /></Card>
  //},
  //{
  //  path: "/:model/update/:id",
  //  main:(path) =><Card style={{ padding: '1rem' }}> <DynaForm model={path.match.params.model} /></Card>
  //},
]

const useStyles = makeStyles((theme) => {
  //console.log(theme.palette)
    return {
      root: {
        display: 'flex',
        backgroundColor: theme.palette.background.default
      },

      appHead:{
        position: "absolute",
        //background: "linear-gradient(145deg, #f09a13 10%, #dd4c39 90%)",
        background: "linear-gradient(160deg, "+theme.palette.primary.main+" 30%, "+theme.palette.secondary.main+" 70%)",
        height: "200px",
        width:"100%"
      }
    }

  }
)

let dependenciesLoaded = false;


function loadDependencies() {
  if (dependenciesLoaded) {
    return;
  }

  dependenciesLoaded = true;

  loadCSS(
    'https://fonts.googleapis.com/icon?family=Material+Icons',
    document.querySelector('#material-icon-font'),
  );

  loadCSS(   '//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css' );
  //loadCSS(   '//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css' );
  //loadCSS(   '' );
  //loadScript('https://www.google-analytics.com/analytics.js', document.querySelector('head'));
}

function AppWrapper(props) {
  const { children, } = props;

  React.useEffect(() => {
    loadDependencies();
  }, []);

  return (
    <React.Fragment>
       {children}
    </React.Fragment>
  );
}














const SwipeableTemporaryDrawer = () =>{
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };



  return <Fragment>

          <IconButton
            edge="start"
            //color="inherit"
            aria-label="open menu"
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton>

          <SwipeableDrawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
          <MainMenu />

          </SwipeableDrawer>
    </Fragment>
  ;
}























function App() {
  const classes=useStyles()
  const { loading,getTokenSilently } = useAuth0();
  const dispatch = useDispatch()
  const load_company = (payload) => dispatch({ type: 'load-company' ,payload:payload})


     const [waitingWorker, setWaitingWorker] = React.useState({});
     const [newVersionAvailable, setNewVersionAvailable] = React.useState(false);
     const [snackar, setSnackar] = React.useState(false);

  React.useEffect(() => {

    window.process = {//process is missing in react
      ...window.process,
    };


    if(!loading && linCompany()){
      dispatch({ type: 'select-company' ,payload:linCompany()})

      ModelRequester('company',linCompany(),getTokenSilently).then((data)=>{
        //setLoading(false)
        //console.log(data.data)
        const extended = mergeDeep(translationsObject,data.data.translations)
        //dispatch(setLocale(data.data.user));
        dispatch(loadTranslations(extended));
        load_company(data.data)
        //setData(colData)
      })
    }
  }, [loading])






     const onServiceWorkerUpdate = (registration) => {
       //console.log("onServiceWorkerUpdate")
        setWaitingWorker(registration && registration.waiting)
        setNewVersionAvailable(true)
        setSnackar(true)

        fetch('/manifest.json')
        .then(response => response.json())
        .then(data => {
          console.log(data)
          localStorage.setItem('build', data.build);
        });
    };

   const updateServiceWorker = () => {
     //console.log("updateServiceWorker")

     waitingWorker && waitingWorker.postMessage&&waitingWorker.postMessage({ type: "SKIP_WAITING" });
     setNewVersionAvailable(false)
     setSnackar(false)
     localStorage.removeItem('build');

     window.location.reload();
   };

  React.useEffect(() => {

    serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate });
    if(localStorage.getItem('build'))
      setSnackar(localStorage.getItem('build')!=packageInfo.build)

  }, [])


   if (loading) {
     return <Fragment><Translate value="App.LoadingTxt" />...</Fragment>;
   }



















  return (
    <AppWrapper>
    <Snackbar
       //anchorOrigin={{
      //   vertical: 'bottom',
      //   horizontal: 'left',
       //}}
       open={snackar}
       //autoHideDuration={6000}
       //onClose={()=>setSnackar(false)}
       onClose={()=>false}
       message="we have an update!"
       action={
         <React.Fragment>
           <Button color="secondary" size="small" onClick={updateServiceWorker}>
             reload
           </Button>
           <IconButton size="small" aria-label="close" color="inherit" onClick={()=>setSnackar(false)}>
             <CloseIcon fontSize="small" />
           </IconButton>
         </React.Fragment>
       }
     />

    <div className={classes.root}>
      <div className={classes.appHead} />
    <div className={'Content-wrapper'}>
        <BrowserRouter>
          <AppBar position="absolute" color={'transparent'} style={{boxShadow:"none",    gridArea: 'header',}}>

            <Toolbar style={{

              //  justifyContent: 'flex-end'

            }}>
            <Hidden mdUp>
              <SwipeableTemporaryDrawer />
            </Hidden>

            <div style={{flexGrow:1}}></div>
            <div>build: {packageInfo.build} company: {linCompany()}</div>


              <UserMenu />
              <SettingsMenu />
              <Avatar style={{backgroundColor:'white'}} alt="linet logo" className={"App-action-shake"} src={'/icon-96.png'} />
            </Toolbar>
          </AppBar>
            <Hidden smDown>
          <MainMenu />
          </Hidden>
          {!linCompany()?
            <Switch >
            <PrivateRoute>{(path)=>
          <div style={{gridArea: 'main'}} ><Suspense fallback={<Fragment><Translate value="App.LoadingTxt" />...</Fragment>}><CompanyAdmin /></Suspense></div>
            }</PrivateRoute>
          </Switch>
          :
          <Switch >
            {routes.map((route, index) => (
              <PrivateRoute
                key={index}
                path={route.path}
                exact={route.exact}
                //withAuth//NoMatch
              >
                {route.main}
              </PrivateRoute>
            ))}
            {<PrivateRoute>{(path)=>
              <Suspense fallback={<div><Translate value="App.LoadingTxt" />...</div>}>
            {<LinFrame path={path} />}</Suspense>}
            </PrivateRoute>}
          </Switch>}
        </BrowserRouter>
      </div>

    </div>
  </AppWrapper>
  );
}
export default App
