
export const translationsObject={
  en_us:{
    Company:{


      DemoDataInitTxt:"We are on it!\n Working on creating demo products clients, suppliers, documents etc.\n We are almost there",
      DataInitTxt:"We are on it!\n Working on creating the business you asked to",



      TYPE_BI_MONTHLY:"Bi-Monthly",
      TYPE_MONTHLY:"Monthly",

      TYPE_REGULAR:"Regular",
      TYPE_EXEMPT:"Exempt",
      TYPE_LTD:"LTD",
      TYPE_NPO:"NPO",

    },

  },

  he_il:{

    PosState:{
      type1Txt:"פתיחה",
      type0Txt:"סגירה",
      typeTxt:"סוג",
      pos_idTxt:"קופה",
      user_idTxt:"משתמש",
      creditSumWasTxt:"סכום אשראי",
      creditSumCalcTxt:"צפי אשראי",
      cheqCountWasTxt:"מספר צ'ק'",
      cheqCountCalcTxt:"צפי מספר ציק",
      cheqSumWasTxt:"סכום צ'ק'",
      cheqSumCalcTxt:"צפי צ'ק'",
      cashSumCalcTxt:"צפי מזומן",
      cashSumWasTxt:"סכום מוזמן",
      createdTxt:"תאריך",


    },

    Mail:{
      SendMailTxt:"שליחת דואל",
      toTxt:"אל",
      subjectTxt:"נושא",
      bodyTxt:"גוף",
      CancelTxt:"ביטול",
      SendTxt:"שלח",

    },
    CpaChild:{
      idTxt:"מזהה",
      src_idTxt:"מקור",
      status_idTxt:"סטאטוס",
      account_idTxt:"ספק",
      oppt_account_idTxt:"הוצאה",
      nameTxt:"שם קובץ",
      refnum_extTxt:"אסמכתא",
      totalTxt:"סהכ",
      issue_dateTxt:"תאריך מסמך",
      imgTxt:"תמונה",
      ref_dateTxt:"תאריך אסמכתא",

      create_dateTxt:"תאריך יצירה",
      reg_dateTxt:"תאריך רישום",

      status0Txt:"נשלח",
      status5Txt:"נשלח",
      status10Txt:"בוצע",
      status15Txt:"דווח",
      status20Txt:"ארכיון",



    },
    CpaParent:{
      assetTxt:"נכס",

      PaymentTxt:"תשלום",
      AddPaymentTxt:"הוספת תשלום",

      ActionsTxt:"פעולות",
      child_idTxt:"מזהה",
      company_idTxt:"חברה",
      tenanet_idTxt:"דייר",
      idTxt:"מזהה",
      src_idTxt:"מקור",
      status_idTxt:"סטאטוס",
      account_idTxt:"ספק",
      oppt_account_idTxt:"הוצאה",
      nameTxt:"שם קובץ",
      refnum_extTxt:"אסמכתא",
      create_dateTxt:"תאריך יצירה",
      ownerTxt:"משתמש",

      currencyTxt:"מטבע",

      ext_refnumTxt:"מספר מסמך",
      ref_dateTxt:"תאריך מסמך",
      reg_dateTxt:"חודש דיווח",
      valuedateTxt:"תאריך ערך",

      cheque_numTxt:"מספר צ'ק'",
      cheque_bankTxt:"בנק",
      branchTxt:"סניף",
      cheque_acctTxt:"חשבון",
      cheque_dateTxt:"תאריך",
      auth_numberTxt:"אישור עסקה",
      bank_account_idTxt:"חשבון בנק",
      due_dateTxt:"תאריך",
      refnumTxt:"אסמכתא",



      sub_totalTxt:"סהכ לפני",
      vatTxt:"מעמ",
      knownVatTxt:"מעמ מוכר",
      totalTxt:"סהכ",
      BackTxt:"חזרה",
      GoTxt:"סע"
    },

    CpaGlobal:{
      status0Txt:"נשלח",
      status5Txt:"נקרא",
      status10Txt:"בוצע",
      status15Txt:"דווח",
      status20Txt:"ארכיון",


      src1Txt:"אתר",
      src5Txt:"מייל",
      src10Txt:"נייד",

    },

    Production:{
      doc_createdTxt:"תאריך הזמנה",
      doc_docnumTxt:"סימוכין",
      item_nameTxt:"שם פריט",
      item_sizeTxt:"מידה",
      numberTxt:"הדפסה",
      textTxt:"תוספת",
      doc_ownerTxt:"עמדת הזמנה",
      status_nameTxt:"סטאטוס",
    },

    ProductionStation:{
      idTxt:"מזהה",
      chain_idTxt:"שרשרת יצור",
      nameTxt:"שם תחנה",
      input_statusTxt:"סטאטוס נכנס",
      output_statusTxt:"סטאטוס יוצא",
    },

    ProductionStatus:{
      numTxt:"מזהה סטאטוס",
      chain_idTxt:"שרשרת יצור",
      nameTxt:"שם",
    },

    ProductionChain:{
      idTxt:"מזהה",
      nameTxt:"שם",
    },

    DoctypeName:{
      Proforma:"חשבונית עסקה",
      DeliveryDocument:"תעודת משלוח",
      Invoice:"חשבונית מס",
      CreditInvoice:"חשבונית זיכוי",
      ReturnDocument:"תעודת החזרה",
      Quote:"התעת מחיר",
      SalesOrder:"הזמנת עבודה",
      Receipt:"קבלה",
      PerformaReceipt:"חשבונית עסקה קבלה",
      InvoiceReceipt:"חשבונית מס קבלה",
      PurchaseOrder:"הזמנת רכש",


      ReceiptForDonation:"קבלה על תרומה",
    },

    Company:{
      DetailsTxt:"פרטי קשר",
      TaxTxt:"מיסים",
      DocnumTxt:"מספרי מסמכים",


      CompanyTypeTxt:"סוג עסק",
      DoublebookTxt:"הנהלת חשבונות כפולה",
      StockManageTxt:"ניהול מלאי",
      PreTaxTxt:"תקופת דיווח מיקדמות למס הכנסה",
      VatTaxTxt:"תקופת דיווח למעמ",
      PreTaxRateTxt:"שיעור מקדמות מס הכנסה",


      DemoDataInitTxt:"עובדים על זה!\n יוצרים לקוחות דמה, ספקי דמה, פריטים ומסמכם דמה.\nעוד דקה ויש לך את זה",

      DataInitTxt:"עובדים על זה!\n יוצרים את העסק שביקשת להקים",


      TYPE_BI_MONTHLY:"דו חודשי",
      TYPE_MONTHLY:"חודשי",

      TYPE_REGULAR:"עוסק מורשה",
      TYPE_EXEMPT:"עוסק פטור",
      TYPE_LTD:'חברה בע"מ',
      TYPE_NPO:"עמותה",
    },

    general:{
      VatnumTxt:"מזהה יישות",
      Vatnum1Txt:"מספר עוסק",
      Vatnum2Txt:"מספר עוסק",
      Vatnum3Txt:"מספר חפ",
      Vatnum4Txt:"מספר עמותה",


      NameTxt:"שם",
      PhoneTxt:"טלפון",
      AddressTxt:"כתובת",
      CountryTxt:"מדינה",
      LogoTxt:"לוגו",
      FaxTxt:"פקס",
      CityTxt:"עיר",
      ZipTxt:"מיקוד",
    },

    App:{
      NOPAY:"ללא תשלום",
      CASH:"מזומן",
      CHEQ:"צ'ק'",
      CREDIT:"אשראי",
      BANKTRANSFER:"העברה",
      //MANULCREDIT:"",
      //PAYCREDIT:"",
      SRCTAX:"ניקוי מס במקור",
      //PAYPAL:"",
      //CREDITCARD_CREDIT:"",
      COMPLEX:"תשלום תשלום מורכב",

      BackTxt:"חזרה",
      OkTxt:"אישור",
      CancelTxt:"ביטול",
      DeleteTxt:"מחיקה",
      ViewTxt:"הצגה",
      UpdateTxt:"עריכה",
      PrintTxt:"הדפסה",


      CompanyTxt:"חברה",
      LoadingTxt:"טוען",
    },

    Table:{
      ColumnsTxt:"טור",
      FiltersTxt:"סינון"

    },

    docdetailes:{
      item_idTxt:"פריט",


      searchTypeTxt:"סוג מסמך",
searchAccount_idTxt:"מזהה לקוח",
searchAccount_catTxt:"קטגורית לקוח",
searchCompanyTxt:"שם לקוח",
searchNumTxt:"מספר מסמך",
nameTxt:"שם",
iItemTxt:"מחיר יחידה",
currency_idTxt:"מטבע",
currency_rateTxt:"שער מטבע",
account_idTxt:"חשבון הכנסות",
warehouse_idTxt:"מחסן",
vat_cat_idTxt:"קטגורית מעמ",
qtyTxt:"כמות",
iTotalTxt:"סהכ לשורה לפני מס",
iTotalVatTxt:"סהכ לשורה",
descriptionTxt:"תיאור",
unit_idTxt:"סוג יחידה",


      searchPurchasepriceTxt:"מחיר קניה",
      searchProfitTxt:"רווח לפריט",
      searchLineProfitTxt:"רווח לשורה",
      searchProfitPerTxt:"אחוז רווח",
      searchCategory_idTxt:"קטגורית פריט",
      searchIssueTxt:"תאריך מסמך",
      searchDueTxt:"תאריך יעד",
      searchRefTxt:"תאריך אסמכתא",
      lineTxt:"שורה",
      searchOwnerTxt:"משתמש",
      searchSkuTxt:"מקט",
      searchManufacturerTxt:"יצרן",



    }

  }



}
