export const table = {

  label:"Account Category",
  exportOpt:{

    sheetName:"Account Category",
    fileName:"Account Category"
  },

  clmns:[
    {
      'id': 'id',
      'visible':true,
      'type':'number'
    },
    {
      'id': 'name',
      'visible':true,
    },
    {
      'id': 'type_id',
      'visible':true,
    },

    { id: "btns",type:"action",payload:[ 'edit',    'delete', ]},//btns
  ]

}
