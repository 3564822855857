//import { dbMain } from '../reducers/database/main';
//import {csrfToken} from '../helpers/csrfToken.js';
import {linHostUrl} from '../helpers/linHostUrl'
import {linCompany} from '../helpers/linCompany'

const axios = require('axios');




export function accountLogout(){
  return function(dispatch){
    dispatch({type:"ACCOUNT_LOGOUT",payload:0});
  }
}

let promisesAreMade=[];

/*const sleep= (time)=> {
  return new Promise((resolve) => setTimeout(resolve, time));
}*/

export const Requester = async (postParams,url,getTokenSilently)=>{
  if(!postParams){
    return Promise.resolve(false)//promise
  }
  let id=JSON.stringify(postParams)
  //console.log(promisesAreMade)
  var promise = promisesAreMade.filter(({path})=>path===id+url)  //if hasRequest return
  if(promise.length>=1){
    return promise[0]["data"]
  }
  // eslint-disable-next-line no-restricted-globals
  let d=location.hostname
  const token = await getTokenSilently({
          audience: 'https://'+d,
          scope: 'read:data'
        });

  //indexed db! or cache


  // eslint-disable-next-line no-restricted-globals
  if(location.hostname.endsWith('app'))
    document.cookie = "_bearer="+token+";domain=.linet.app;path=/; SameSite=None; Secure";
  else
    document.cookie = "_bearer="+token+";domain=.linet.org.il;path=/; SameSite=None; Secure";



  var promise=axios.post(linHostUrl()+url,
      postParams,
      {
        headers:{
        Authorization:"Bearer "+token,
        Company: linCompany()
          //"X-CSRF-Token":csrfToken()
        },
        //withCredentials:true,
      }
    )
    /*.then(data => {
      console.log(data);
      return data;
    })*/
    .catch(()=>{
      return {}
    })

    .finally((data)=>{//!importent!
      console.log(data)

      promisesAreMade=promisesAreMade.filter(({path})=>path!==id+url)
    })

  promisesAreMade.push({
    path:id+url,
    data:promise
  })
  return promise

}

export const ModelsRequester=(model,query,getTokenSilently)=>{
  return Requester({query:query},'/v6/'+model+'/search',getTokenSilently)
}

export const ModelRequester=(model,id,getTokenSilently)=>{
  return Requester({id:id},'/v6/'+model+'/view',getTokenSilently)
}

export const ModelResolver=(data,attribute)=>{
  if(data.data== null|| !data.data[attribute])
    return ''
  return data.data[attribute]
}




export const ModelFetchAction = (model,query,sort,limit,offset,getTokenSilently) => {

  return Requester({ model : model, query : query, sort : sort,limit:limit,offset:offset },'/v6/'+model+'/search',getTokenSilently)

/*
  return {

  type: 'FETCH_MODELS',
  payload: { model : model, query : query },
  meta: {
    offline: {
      effect: {
        url: linHostUrl()+'/v6/'+model+'/search',
        method: 'POST',
        headers:{
          Authorization:"Bearer " + token,
          Company:linCompany()
        },
        withCredentials:true,

        body: JSON.stringify({ model : model, query : query, sort : sort,limit:limit,offset:offset })
      },
      //effect: { url: 'docs.json', method: 'GET', body:  {account_uuid:account_uuid}  },//POST
      commit: { type: 'FETCH_MODELS_COMMIT', meta: { model : model } },
      rollback: { type: 'FETCH_MODELS_ROLLBACK', meta: {  } },
    }
  }
}*/
};
















export const ModelCreateAction = (model,data) => ({
  type: 'CREATE_MODEL',
  payload: {model:model,data:data},
  meta: {
    offline: {
      effect: {
        url: '/linet3extend/api/create?model='+model,
        method: 'POST',
        //headers:{Authorization:"Bearer "+window.localStorage.getItem("serverAccessToken")},
        body: JSON.stringify({model:model,data:{[model]:data}})
      },
      commit: { type: 'CREATE_MODEL_COMMIT', meta: { model:model,data:data } },
      rollback: { type: 'CREATE_MODEL_ROLLBACK', meta: {  } },
    }
  }
});
























export function DataSetAttr(attr,value){
  return function(dispatch){
    dispatch({type:"DATA_SET_ATTR",payload:{attr:attr,value:value}});
  }
}



export const ModelBulkUpdateAction = (model,query,data) => ({
  type: 'BULK_UPDATE_MODEL',
  payload: {model:model,query:query,data:data},
  meta: {
    offline: {
      effect: {
        url: '/linet3extend/api/bulkupdate?model='+model,
        method: 'POST',
        headers:{Authorization:"Bearer "+window.localStorage.getItem("serverAccessToken")},

        body: JSON.stringify({model:model,data:data,query:query})
      },
      commit: { type: 'BULK_UPDATE_MODEL_COMMIT', meta: {  model:model,data:data } },
      rollback: { type: 'BULK_UPDATE_MODEL_ROLLBACK', meta: {  } },
    }
  }
});




export const ModelUpdateAction = (model,id,data) => ({
  type: 'UPDATE_MODEL',
  payload: {model:model,id:id,data:{[model]:data}},
  meta: {
    offline: {
      effect: {
        url: '/linet3extend/api/update?model='+model,
        method: 'POST',
        headers:{Authorization:"Bearer "+window.localStorage.getItem("serverAccessToken")},
        body: JSON.stringify({model:model,data:{[model]:data},query:id})
      },
      commit: { type: 'UPDATE_MODEL_COMMIT', meta: {  model:model,data:data } },
      rollback: { type: 'UPDATE_MODEL_ROLLBACK', meta: {  } },
    }
  }
});
