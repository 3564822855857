
export const tValue= (sum,obj) => {
  if (obj._currency_rate >= 1) {
    sum = sum / obj._currency_rate;
  } else {
    sum = sum * obj._currency_rate;
  }

  return sum;
}
