export const table = {

  label:"Document Type",
  exportOpt:{
    sheetName:"Document Type",
    fileName:"Document Type"
  },

  clmns:[
    {
      'id': 'name',
      'visible':true,
    },
    {
      'id': 'last_docnum',
      'visible':true,
    }
  ]
}
