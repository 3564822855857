import {round} from '../helpers/round.js';
import {aValue} from '../helpers/aValue.js';



export const loadLine=(item,allValues)=>{

  console.log(item)
  console.log(allValues)

  return {

    item_id: item.id,

    name: item.name,
    cost: item.purchaseprice,
    pcurrency: getCur(item.purchase_currency_id, allValues),

    profit_value: item.profit,
    profit: { type: item.profitUp, val: 0 },

    iItem: { price: item.saleprice, withVat: item.vatIn },
    currency: getCur(item.currency_id,allValues),

    unit: getUnit(item.unit_id,allValues),

    qty: 1,

    vat_cat: {id:item.itemVatCat_id,tax_rate:17},//item.extra.vat,
    account: item.income_acc,

    discount:{val:0,per:0,type:0},

    iTotalVat:item.saleprice,
    warehouse: allValues.warehouse,

    //warehouse:{id:item.warehouse_id:allValues.warehouse.name},
    //warehouse: getWarehouse(item.warehouse_id,allValues)
    //iTotal:,
    //vat:,
    //discount:body.discount,
    //discountType:(typeof body.discount=='undefined' || body.discount==0?1:0),

  }


}


export const getDet=(fname,name,allValues)=>{
  console.log("."+name+" dec")
  const index = (fname.replace("]."+name, '')).replace('docDetails[', '')
  const basename = fname.replace(name, '')

  let det=allValues.docDetails[index]

  if(!det||!det.item_id)
    return false

  det._currency_rate=(det.currency.currency_rate*1)/(allValues.currency.currency_rate*1)

  return [det,basename,allValues]
}

export const fromItemCalc = (calc) =>{//unused
    if(!calc)
      return {}

    const det=calc[0]
    const basename=calc[1]
    const allValues=calc[2]

    if(!allValues.activeField)
      return {}

    if(
      !allValues.activeField.name.endsWith('iItem')&&
      !allValues.activeField.name.endsWith('currency')&&
      !allValues.activeField.name.endsWith('qty')&&
      !allValues.activeField.name.endsWith('item')&&
      !allValues.activeField.name.endsWith('discount')&&

      !allValues.activeField.name.endsWith('vat_cat')
    )
    return {}
    console.log('fromItemCalc')


    return baser(baseCalc(det),basename)


}


export const fromDiscountCalc=(calc)=>{

    if(!calc)
      return {}
    const det=calc[0]
    const basename=calc[1]
    const allValues=calc[2]

    if(!allValues.activeField)
      return {}

    if(
      !allValues.activeField.name.endsWith('discount')

    )
    return {}

    let nextdet=baseCalc(det)

    delete nextdet.discount;


    return baser(nextdet,basename)



}


export const fromProfitCalc=(calc)=>{

    if(!calc)
      return {}
    const det=calc[0]
    const basename=calc[1]
    const allValues=calc[2]

    if(!allValues.activeField)
      return {}

    if(
      !allValues.activeField.name.endsWith('profit')

    )
    return {}


    var vatRated = det.vat_cat.tax_rate/100+1;

    var ratedSaleprice = det.iItem.price*det.currency.currency_rate;

    var ratedPurchaseprice = det.cost*det.pcurrency.currency_rate;

    console.log('fromProfitCalc')
    //if(det.item.pvatIn==1)
    //  ratedPurchaseprice=ratedPurchaseprice/vatRated

    if(det.iItem.withVat=='1')
      ratedSaleprice=ratedSaleprice/vatRated;


    var profit_value = round(((det.profit.val / 100) * ratedPurchaseprice)/det.pcurrency.currency_rate);

    if(det.profit.type==1)
      var profit_value =round((ratedPurchaseprice/Math.abs(1-(det.profit.val / 100))-ratedPurchaseprice)/det.pcurrency.currency_rate);

    var iitem=ratedPurchaseprice+profit_value*1//linet3.app.round
    if(det.iItem.withVat==1)
      iitem=(ratedPurchaseprice+profit_value*1)*vatRated//linet3.app.round

    let nextdet={...det,iItem:{...det.iItem,price:iitem}}


    let deta = baseCalc(nextdet)

    delete deta.profit;

    deta.iItem={...det.iItem,price:iitem}

    return baser(deta,basename)


}


export const dueDateCalc=(payterms,date)=>{
  if(payterms==0){
    return date
  }

  var date = new Date(date);
  const copy = new Date(Number(date))


  payterms=payterms+""



  if(payterms=="915"){
    return addDays(addMonth(copy,0),15)
  }

  if(payterms=="930"){
    return addMonth(copy,1)
  }

  if(payterms=="940"){
    return addDays(addMonth(copy,1),10)
  }

  if(payterms=="945"){
    return addDays(addMonth(copy,1),15)
  }

  if(payterms=="960"){
    return addMonth(copy,2)

  }

  if(payterms=="990"){
    return addMonth(copy,3)
  }

  if(payterms=="9120"){
    return addMonth(copy,4)
  }

  return addDays(copy,payterms)
}

const isLeapYear = function (year) {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
};

const getDaysInMonth = function (year, month) {
    return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

export const addMonth=(date,months)=>{

  date.setDate(1);
  date.setMonth(date.getMonth() + months);
  date.setDate( getDaysInMonth(date.getFullYear(), date.getMonth()));
  return date;
}

const addDays=(date,days)=>{

  date.setDate(date.getDate() + (days*1))
  return date
}



export const getWarehouse=(account_id,allValues)=>{
  let account={id:account_id,name:account_id}
  if(allValues&& Array.isArray(allValues.warehouse_list)){
    allValues.warehouse_list.map(acc=>{
      if(acc.id==account_id)
        account=acc
    })
  }
  return account
}



export const getUnit=(unit_id,allValues)=>{
  let unit={id:unit_id,name:unit_id}
  if(allValues&& Array.isArray(allValues.unit_list)){
    allValues.unit_list.map(unt=>{
      if(unt.id==unit_id)
        unit=unt
    })
  }
  return unit
}





export const getCur=(currency_id,allValues)=>{
  let currency={currency_id:currency_id,currency_rate:1,symbol:"@"}
  if(allValues&& Array.isArray(allValues.currency_list)){
    allValues.currency_list.map(cur=>{
      if(cur.currency_id==currency_id)
        currency=cur
    })
  }
  return currency
}

export const baser=(obj,basename)=>{
  let newobj={}
  Object.keys(obj).map(function(key, index) {
    newobj[basename+key] = obj[key];
  });

  return newobj
}

export const baseCalc=(det)=>{


  var vatRate = det.vat_cat.tax_rate / 100;
  if(det.qty == 0){
    return {
      iItemVat: 0,
      iTotalVat: 0,
      iTotal: 0,
    };
  }
  var discountMulti = 1;
  if(det.qty < 0){
    discountMulti = -1;
  }

  var qty = det.qty;
  if(det.bundle && det.bundle.uuid != '' && det.bundle.qty != 0 && det.bundle.size > 1)
    qty = det.bundle.qty;

  var iLine = aValue(det.iItem.price * qty,det, -1);

  if(det.iItem.withVat != 1){
    iLine *= (1 + vatRate);//
  }

  let discount_value = det.discount.val

  if(det.discount.type == 1){
    discount_value = round(iLine*(det.discount.per/100))*discountMulti;
  }
  iLine -= discount_value*discountMulti;

  let iItemVat = round((iLine / (vatRate+1))/det.qty *vatRate);
  let iTotal = round(iLine/ (vatRate+1));
  let iTotalVat = round(iLine);

  let iItem=iLine / ((1 + vatRate))

  var ratedPurchaseprice=det.cost*det.pcurrency.currency_rate;

  console.log('fromProfitValueCalc')

  if(det.item.pvatIn==1)
    ratedPurchaseprice=ratedPurchaseprice/(vatRate+1)

  return {
    discount:{...det.discount, val: discount_value},//round
    //profit:iLine-det.cost,
    profit_value: round(iItem-ratedPurchaseprice),//round
    vat: iTotalVat-iTotal,//round
    iItemVat: iItemVat,//crap ware
    iTotalVat: iTotalVat,//round
    iTotal: iTotal,//round
  };

}

export const fromProfitValueCalc=(calc)=>{
  if(!calc)
    return {}
  const det=calc[0]
  const basename=calc[1]
  const allValues=calc[2]

  if(!allValues.activeField)
    return {}

  if(
    !allValues.activeField.name.endsWith('profit_value')
  )
  return {}

  var vatRated=det.vat_cat.tax_rate/100+1;

  var ratedPurchaseprice=det.cost*det.pcurrency.currency_rate;

  console.log('fromProfitValueCalc')

  if(det.item.pvatIn==1)
    ratedPurchaseprice=ratedPurchaseprice/vatRated

  var iitem=ratedPurchaseprice+det.profit_value*1//linet3.app.round
  if(det.iItem.withVat==1)
    iitem=(ratedPurchaseprice+det.profit_value*1)*vatRated//linet3.app.round

  let nextdet={...det,iItem:{...det.iItem,price:iitem}}

  let deta = baseCalc(nextdet)

  delete deta.profit;
  delete deta.profit_value;

  deta.iItem={...det.iItem,price:round(iitem)}

  return baser(deta,basename)

}

export const fromBCalc=(calc)=>{

  if(!calc)
    return {}
  const det=calc[0]
  const basename=calc[1]


  //calcIItem
  //remove profit_value
  return {}
}


export const fromTotalCalc=(calc)=>{

  if(!calc)
    return {}
  const det=calc[0]
  const basename=calc[1]
  const allValues=calc[2]

  if(!allValues.activeField)
    return {}

  if(
    !allValues.activeField.name.endsWith('iTotalVat')
  )
  return {}








  //console.log('CalcPriceWithVat');
  var vatRated=det.vat_cat.tax_rate/100+1;
  if(det.qty==0){

    return {
      iItemVat:0,
      iTotalVat:0,
      iTotal:0
    };
  }
  var discountMulti=1;
  if(det.qty<0){
    discountMulti=-1;
  }

  det.iItemVat=round(det.iTotalVat-det.iTotalVat / vatRated/det.qty);///?????
  det.iTotal=det.iTotalVat / vatRated;

  if(det.discount.type==1){
    var per=det.discount.per/100
    det.discount.val=round(det.iTotalVat/(1-per)*per*discountMulti);
  }
  //console.log(obj.iTotalVat);
  //console.log(obj.discount);
  //if bundle!
  var qty=det.qty;
  if(det.bundle_uuid!='' && det.bundleQty!=0 && det.bundleSize>1)
    qty=det.bundleQty;
  det.iItem=aValue((det.iTotalVat+det.discount*discountMulti)/qty,det, 1);
  if(det.iItem.withVat!=1){
    det.iItem=round(det.iItem/vatRated);
  }else{
    det.iItem=round(det.iItem);
  }
  return baser(det,basename)








  //let nextdet={...det,iItem:{...det.iItem,price:iitem}}

  //let deta = baseCalc(nextdet)

  //return baser(deta,basename)

}
