import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';

import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import { ThemeProvider } from './components/ThemeContext';


import { Auth0Provider } from "./react-auth0-spa";

import store from './store.js';
import history from "./helpers/history";


const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
  //nsertionPoint: process.browser ? document.querySelector('#insertion-point-jss') : null,
  insertionPoint: document.querySelector('#insertion-point-jss') ,

});

const onRedirectCallback = appState => {
  console.log(appState.targetUrl)
  console.log(window.location.pathname)
  console.log(appState)

  history.push(
  //window.location.assign(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const config={
  domain: "linet.eu.auth0.com",
  clientId: "k0otNq7O5W6id3xhkvqKR1CCtEhtxLG2",
}


ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      >
        <Provider store={store}>
          <StylesProvider jss={jss}>
             <ThemeProvider><App /></ThemeProvider>
          </StylesProvider>
        </Provider>
      </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
