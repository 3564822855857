export const table = {

  label:"Category Admin",
  exportOpt:{

    sheetName:"Category Names",
    fileName:"Category"
  },

  

  clmns:[
    
    /*{
      'id': 'parent_id',
      'visible':true,
    },//*/
    {
      'id': 'id',
      'visible':true,
    },

  

    {
      'id': 'priority',
      'visible':true,
    },

    {
      'id': 'discount',
      'visible':true,
    }

  ]

}
