import React from 'react';

import { Link } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { Translate } from 'react-redux-i18n';

const items=[

  {"label":"Business Details","url":"/settings/admin","icon":null},
  {"label":"Business Docs","url":"/doctype/admin","icon":null},
  {"label":"Custom Fields","url":"/eavfields/admin","icon":null},
  {"label":"Currency Rates","url":"/linet3cur/currates/admin","icon":null},
  {"label":"Opening Balances","url":"/transaction/openbalance","icon":null},
  {"label":"Manage Users","url":"/users/admin","icon":null},
  {"label":"Mail Template","url":"/mailtemplate/admin","icon":null},
  {"label":"SMS Templates","url":"/smstemplate/admin","icon":null},
  {"label":"Payment Admin","url":"/payment/admin","icon":null},
  {"label":"Document Template","url":"/doctemplate/admin","icon":null},

  {"label":"General Backup","url":"/data/backup","icon":null},
  {"label":"Restore From Backup","url":"/data/restore","icon":null},

  {"label":"Subscription Management","url":"/linet3extend/subscription/manage","icon":null}

  //{"label":"Import Open Format","url":"/data/openfrmtimport","icon":null}
  //{"label":"About","url":"/settings/about","icon":null}
  //{"label":"Bug Report","url":"/settings/bug","icon":null},



]


  const SettingsMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };


  return (  <div>
      <IconButton aria-controls="settings-menu" aria-haspopup="true" onClick={handleClick}>
        <SettingsIcon
        className={"App-action-spin"}
        //color="primary"
        />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
      {items.map((item,index)=>
        <MenuItem
        key={index}

        {...{ to: item.url }}
       component={Link}
        onClick={handleClose}><Translate value={"app."+item.label}/></MenuItem>
      )}
      </Menu>
    </div>
  );
}
  export default SettingsMenu
