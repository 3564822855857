import React from 'react';

import { Link } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';

import { useAuth0 } from "../../react-auth0-spa";
import { Translate } from 'react-redux-i18n';
import { useSelector } from 'react-redux';

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout } = useAuth0();
  const { user } = useSelector(state => state.AppReducer)

  var items=[
    //{"label":"Logout","url":"logout","icon":null},
    {"label":"Update User","url":"/auth/users/update?id="+user.id,"icon":null},
    {"label":"Change Company","url":"/company/index","icon":null},
  ]
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (  <div>
      <IconButton aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
        <PersonIcon
        className={"App-action-shake"}
        //color="primary"
        />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item,index)=>
          <MenuItem
          key={index}
          {...{ to: item.url }}
         component={Link}
          onClick={handleClose}><Translate value={"app."+item.label}/></MenuItem>
        )}

        <MenuItem
       onClick={() => { logout({ returnTo: window.location.origin+"/dashboard" })  }}
        ><Translate value={"app.Logout"}/></MenuItem>
      </Menu>
    </div>
  );
}
export default UserMenu
