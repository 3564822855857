export const table = {

  label:"Sales Report",
  exportOpt:{

    sheetName:"Sales Report",
    fileName:"Sales Report"
  },

  clmns:[
      {
        'id' :'searchType',
        'aggregate': 'count',
        'width': 50,

        'disableGroupBy': true,
        'opt':"doctype",

        'type': 'modelResolver',
        'resolveModel':'doctype',
        'resolveAttribute':'name',
        'visible':true,


        'type':'filterSelect',



      },
      {
        'id' : 'searchAccount_id',
        'aggregate': 'count',
        'width': 50,
        'type':'filterSelect',

        //'filter': 'equals',
        'visible':true,

      },

      {
        'id' : 'searchCategory_id',
        'visible': false,
        'aggregate': 'count',

        'type':'filterSelect',


        //'opt':"acccat",

        //'type': 'modelResolver',
        //'resolveModel':'acccat',
        //'resolveAttribute':'name',
      },

      {
        'id' : 'searchCompany',
        'aggregate': 'count',

        'visible':true,

      },

      {
        'field': 'description',
        'id': 'doc.description',
        'visible':false,
        'disableGroupBy': true,
      },
      {
        'field': 'comments',

        'id' : 'doc.comments',
        'visible':false,
        'disableGroupBy': true,

      },
      {
        'id' : 'searchNum',
        'disableGroupBy': true,
        'visible':true,

        'type':'number',

      },//link
      {
        'id' : 'item_id',
        'aggregate': 'count',


        'type':'filterSelect',

        'filter': 'equals',
        'width': 50,
        'visible':true,


      },
      {
        'id' : 'name',
        'aggregate': 'count',
        'visible':true,
      },
      {
        'id' : 'iItem',
        'type':'number',

        'aggregate': 'sum',

        'disableGroupBy': true,
        'width': 50,
        'visible':true,


      },
      {
        'id' :  'currency_id',

        'type':'filterSelect',

        'visible' : false,
        'width': 50,
      },
      {
        'id' :  'currency_rate',
        'visible' : false,
        'disableGroupBy': true,
        'aggregate': 'avg',

        'width': 50,
      },
      {
        'id' : 'account_id',
        'value' : 'income.name',

        'type':'filterSelect',

        'visible' : false,
        'aggregate': 'count',

        //promise?

      },
      {
        'id' : 'warehouse_id',
        'visible' : false,

        'type':'filterSelect',


        'aggregate': 'count',

        //promise?

      },
      {
        'id' :  'vat_cat_id',
        'visible' : false,
        'aggregate': 'count',

        'opt':"itemvatcat",

        'type': 'modelResolver',
        'resolveModel':'itemvatcat',
        'resolveAttribute':'name',



        'type':'filterSelect',

      },
      {
        'id' :  'unit_id',
        'visible' : false,
        'opt':"itemunit",

        'type': 'modelResolver',
        'resolveModel':'itemunit',
        'resolveAttribute':'name',

        'type':'filterSelect',


      },
      {
        'id' :  'qty',
        'type':'number',
        'aggregate': 'sum',

        'width': 50,
        'visible':true,

        /*'pageSummary' => function ($summary, $data, $widget) use($model){
          return $model->reportSearchTotal("qty");
        }*/
      },
      {
        'id' :  'iTotal',
        'type':'number',
        'aggregate': 'sum',
        'disableGroupBy': true,
        'visible':true,

        /*'pageSummary' => function ($summary, $data, $widget) use($model){
          return $model->reportSearchTotal("iTotal");
        }*/
      },
      {
        'id' :  'iTotalVat',
        'visible' : false,
        'type':'number',
        'aggregate': 'sum',

        'disableGroupBy': true,
        /*
        'pageSummary' => function ($summary, $data, $widget) use($model){
          return $model->reportSearchTotal("iTotalVat");
        }
        */
      },
      {
        'id' :  'description',
        'visible':false,
        'disableGroupBy': true,
      },
      {
        'id' :  'searchSku',
        'visible' : false,
      },
      {
        'id' :  'searchManufacturer',
        'visible' : false,
        'aggregate': 'sum',

      },
      {
        'id' :  'searchPurchaseprice',
        'visible' : false,
        'type':'number',
        'aggregate': 'sum',

        'disableGroupBy': true,
      },
      {
        'id' :  'searchProfit',
        'visible' : false,
        //'type':'number',
        'aggregate': 'avg',

      },
      {
        'id' : 'searchLineProfit',
        'visible' : false,
        //'type':'number',
        /*'pageSummary' => function ($summary, $data, $widget) use($model){
          return $model->reportSearchTotal("searchLineProfit");
        }*/
        'aggregate': 'sum',
        'Aggregated': ({ value }) => `${value} sum`,
        'disableGroupBy': true,
      },
      {'id' : 'searchProfitPer',
        'visible' : false,
        //'type':'number',
        'aggregate': 'avg',
        'disableGroupBy': true,
      },
      {
        //'filter' :  \yii\helpers\ArrayHelper::map(\app\models\Itemcategory::find()->asArray()->all(), 'id', 'name'),
        'id' : 'searchCategory_id',
        'visible' : true,
        'aggregate': 'count',
        'Aggregated': ({ value }) => `${value} Items`,
        'opt':"itemcategory",

        'type': 'modelResolver',
        'resolveModel':'itemcategory',
        'resolveAttribute':'name',


        'type':'filterSelect',

      },
      {
        'id' : 'searchIssue',
        'type':'date',
        'visible':true,

      },
      {
        'id' :'searchDue',
        'type':'date',
        'visible':false,

      },
      {
        'id' : 'searchRef',
        'type':'date',
        'visible':false,

      },
      {
        'id' : 'line',
        'visible' : false,
      },
      {
        'id' : 'searchOwner',
        'visible':false,

        'type':'filterSelect',


        //'type': 'modelResolver',
        //'resolveModel':'user',
        //'resolveAttribute':'username',
        //'filter' : \adam2314\yii2_auth0\models\TenantUser::tlist(),

      },

  ]
}
