import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { useSelector } from 'react-redux';
//import Image from 'material-ui-image';

import {linHostUrl} from '../../helpers/linHostUrl'
import { I18n } from 'react-redux-i18n';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(10),
  },
}));

  const MainMenu = () => {
    const classes = useStyles();

    const [open, setOpen] = React.useState({});


    const {main_menu,settings} = useSelector(state => state.AppReducer)


  return ( <Card style={{ gridArea: 'menu',zIndex:1,overflow:"auto"}}>
      <Link
        to={"/dashboard"}
        style={{
        width:"100%",
        textAlign:"center",
        display: "block"
      }}>
        {settings['company.logo'] && settings['company.logo']!=='' ?
            <img
            src={linHostUrl()+'/site/thumbnail/'+settings['company.logo']}
            alt={settings['company.name']}
            //onClick={() => console.log('onClick')}
            //aspectRatio={(16/9)}
             //disableSpinner
           />

          :
          settings['company.name']
        }
        </Link>
      <List >
        {main_menu.map((item, index) => (
          <div key={index}>
          <ListItem button key={index} onClick={()=>setOpen({...open,[index]:!open[index]})}>
            <ListItemIcon><Icon className={item.icon} /></ListItemIcon>
            <ListItemText primary={I18n.t("app."+item.label)} />
            {item.items &&(open[index] ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Collapse in={open[index]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.items && item.items.map((subItem,subIndex)=>
                <ListItem
                className={classes.nested}
                 {...{ to: subItem.url }}
                component={Link}
                button
                key={subIndex}
                >
                  <ListItemText primary={I18n.t((subItem.module?subItem.module:"app")+"."+subItem.label)} />
                </ListItem>
              )}
            </List>
          </Collapse>
          </div>

        ))}
      </List>

    </Card>
  );
}
  export default MainMenu
