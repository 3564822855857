import {
  fromItemCalc,fromTotalCalc,fromDiscountCalc,fromProfitCalc,
  getWarehouse,loadLine,
  fromProfitValueCalc,getDet,getCur,dueDateCalc,getUnit,baser} from '../helpers/lineCalc.js';


const appReducer = function(state={
    tenant_id:false,
    company_id:false,
    user_id:false,

    user:{},
    main_menu:[],
    conf_menu:[],
    models:{},
    settings:{},

    forms:{

      widget:{
        label:'Widget Edit',
        descrption:'',
        //type:requler|wizard:
        //girdParams
        //submit
        //back
        fields: [
          {name:"name",label:"Name",type:"string",rules:['required']},
          {name:"payload",label:"shuts",type:"fieldarray",rules:[],
            payload:[
              {name:"name",label:"name",type:"string",rules:['required']},
              {name:"url",label:"url",type:"string",rules:['required']},
            ]
          }
        ]
      },

      Shurtcut:{
        label:'Shurtcut',
        descrption:'',
        //type:requler|wizard:
        //girdParams
        //submit
        //back
        fields: [
          {name:"Shurtcuts",label:"shuts",type:"fieldarray",rules:[],
            payload:[
              {name:"id",label:"number",type:"string",rules:['required'],payload:{type:'search',model:'Item',query:{},limit:50}},
              {name:"header",label:"Name",type:"string",rules:['required']},
              {name:"link",label:"url",type:"string",rules:['required','integer']},
              {name:"color",label:"color",type:"string",rules:['required','integer']},
            ]
          }
        ]
      },

      Dynaform:{
        label:'Dynaform',
        descrption:'',
        fields: [
          {name:"label",label:"label",type:"string",rules:['required']},
          {name:"descrption",label:"descrption",type:"string",rules:['required']},
          {name:"fields",label:"fields",type:"fieldarray",rules:[],
            payload:[
              {name:"id",label:"number",type:"string",rules:['required'],payload:{type:'search',model:'Item',query:{},limit:50}},
              {name:"name",label:"name",type:"string",rules:['required']},
              {name:"label",label:"label",type:"string",rules:['required']},
              {name:"type",label:"type",type:"string",rules:['required','integer']},
              {name:"rules",label:"rules",type:"string",rules:['required','integer']},
              {name:"payload",label:"payload",type:"string",rules:['required','integer']},//if type downshift,array,panel,tabs
            ]
          }
        ]
      },

      Dynareport:{
        label:'Dynareport',
        descrption:'',
        fields: [
          {name:"table",label:"table",type:"string",rules:['required']},
          {name:"name",label:"name",type:"string",rules:['required']},
          {name:"group",label:"group",type:"string",rules:['required']},
          {name:"join",label:"join",type:"string",rules:['required']},
          {name:"where",label:"where",type:"fieldarray",rules:[],
            payload:[
              {name:"type",label:"type",type:"string",rules:['required']},//<,>.!=,=,=>,+= like
              //s[eciel betwaen or and
              {name:"attribute",label:"attribute",type:"string",rules:['required']},
              {name:"value",label:"value",type:"string",rules:['required']},

              //{name:"rules",label:"rules",type:"string",rules:['required','integer']},
              //{name:"payload",label:"payload",type:"string",rules:['required','integer']},//if type downshift,array,panel,tabs
            ]
          },
          {name:"clmns",label:"clmns",type:"fieldarray",rules:[],
            payload:[
              {name:"attribute",label:"attribute",type:"string",rules:['required'],payload:{type:'search',model:'Item',query:{},limit:50}},
              {name:"type",label:"type",type:"string",rules:['required']},
              {name:"select",label:"select",type:"string",rules:['required']},
              {name:"selectAction",label:"selectAction",type:"string",rules:['required','integer']},
              {name:"defualtValue",label:"defualtValue",type:"string",rules:[]},
              {name:"label",label:"label",type:"string",rules:[]},


              //{name:"rules",label:"rules",type:"string",rules:['required','integer']},
              //{name:"payload",label:"payload",type:"string",rules:['required','integer']},//if type downshift,array,panel,tabs
            ]
          }
        ]
      },

      Menu:{
        label:'Menu',
        descrption:'',
        fields: [
          {name:"RootMenu",label:"shuts",type:"fieldarray",rules:[],
            payload:[
              {name:"id",label:"number",type:"string",rules:['required'],payload:{type:'search',model:'Item',query:{},limit:50}},
              {name:"header",label:"Name",type:"string",rules:['required']},
              {name:"icon",label:"Icon",type:"string",rules:['required']},
              {name:"link",label:"url",type:"string",rules:['required','integer']},
              {name:"color",label:"color",type:"string",rules:['required','integer']},
            ]
          }
        ]
      },


      /*{name:"Taby",label:"MainTabs",type:"tab",rules:[],
        payload:[
          {name:"tab1",label:"Item",payload:[
            {name:"name",label:"name",type:"string",rules:['required']},
            {name:"sum",label:"sku",type:"string",rules:['required']},
            {name:"cat",label:"cat",type:"string",rules:['required']},
          ]},
          {name:"tab2",label:"Price",payload:[
            {name:"vatIn",label:"vatIn",type:"string",rules:['required']},
            {name:"pvatIn",label:"pvatIn",type:"string",rules:['required']},
            {name:"saleprice",label:"saleprice",type:"string",rules:['required']},

            {name:"pprice",label:"pprice",type:"string",rules:['required']},

          ]},
          {name:"tab3",label:"Stock",payload:[
            {name:"vat",label:"stockType",type:"string",rules:['required']},
          ]},
        ]
      },*/


      doc: {
        label: 'Billing Form',
        descrption: '',
        //create

        //start roles
        initUrl: '/v6/doc/initvalues',
        load:[ ],

        decorators: [
            {
              field: /docDetails\[\d+\].item/, // when a deeper field matching this pattern changes...
              updates: (value, name, allValues) => {
                console.log(".item dec")
                const basename = name.replace('item', '')
                if( !value )
                  return {}

                if( !allValues.activeField.name.endsWith('item') )
                  return {}

                return baser(loadLine(value,allValues),basename)

              }
            },
            //*
            {
              field: /docDetails\[\d+\].iTotalVat/, // when a deeper field matching this pattern changes...
              updates:  {
                  total: (ignoredValue, allValues) =>
                    (allValues.docDetails || [])
                      .reduce((sum, {iTotalVat}) =>  sum + Number( iTotalVat || 0), 0),

                  vat: (ignoredValue, allValues) =>
                    (allValues.docDetails || [])
                      .reduce((sum, {vat}) =>  sum + Number( vat || 0), 0),

                  sub_total: (ignoredValue, allValues) =>
                    (allValues.docDetails || [])
                    .reduce((sum, {iTotal,iVatRate}) =>  sum + Number( iVatRate!=0?iTotal:0 || 0), 0),
                  novat_total: (ignoredValue, allValues) =>
                    (allValues.docDetails || [])
                      .reduce((sum, {iTotal,iVatRate}) => sum + Number(  iVatRate==0?iTotal:0  || 0), 0),

                  profit_total: (ignoredValue, allValues) =>
                    (allValues.docDetails || [])
                      .reduce((sum, {profit_value}) => sum + Number(  profit_value || 0), 0),
                }
            },

            {
              field: /docDetails\[\d+\].iTotalVat/, // when a deeper field matching this pattern changes...
              updates: (value, name, allValues) => {
                return fromTotalCalc(getDet(name,"iTotalVat",allValues))
              }
            },
            {
              field: /docDetails\[\d+\].iItem/,
              updates: (value, name, allValues) => {
                return fromItemCalc(getDet(name,"iItem",allValues))
              }
            },
            {
                field: /docDetails\[\d+\].currency/,
                updates: (value, name, allValues) => {
                  return fromItemCalc(getDet(name,"currency",allValues))
                }
            },
            {
                field: /docDetails\[\d+\].qty/,
                updates: (value, name, allValues) => {
                  return fromItemCalc(getDet(name,"qty",allValues))
                }
            },
            {
                field: /docDetails\[\d+\].vat_cat/,
                updates: (value, name, allValues) => {
                  return fromItemCalc(getDet(name,"vat_cat",allValues))
                }
            },
            {
                field: /docDetails\[\d+\].discount/,
                updates: (value, name, allValues) => {
                  return fromDiscountCalc(getDet(name,"discount",allValues))
                }
            },
            {
                field: /docDetails\[\d+\].profit/,
                updates: (value, name, allValues) => {
                  return fromProfitCalc(getDet(name,"profit",allValues))
                }
            },
            {
                field: /docDetails\[\d+\].profit_value/,
                updates: (value, name, allValues) => {
                  return fromProfitValueCalc(getDet(name,"profit_value",allValues))
                }
            },
            {
              field: 'account',
              updates: {
                //if pricer shuld also trigger item
                //discount?
                account_id: (value,allValues) =>  {
                  return value.id
                },
                company: (value,allValues) =>  {
                  return value.name
                },
                currency: (value,allValues) =>  {
                  return getCur(value.currency_id,allValues)
                },
                vatnum: (value,allValues) =>  {
                  return value.vatnum
                },
                address: (value,allValues) =>  {
                  return value.address
                },
                phone: (value,allValues) =>  {
                  return value.phone
                },
                city: (value,allValues) =>  {
                  return value.city
                },
                zip: (value,allValues) =>  {
                  return value.zip
                },
                email: (value,allValues) =>  {
                  return value.email
                },
                due_date: (value,allValues) =>  {
                  return dueDateCalc(value.pay_terms,allValues.issue_date)
                },
              }
            },
            {
              field: 'issue_date',
              updates: {
                //shuld also triger cur list
                ref_date: (value,allValues) =>  {
                  return value
                },
                due_date: (value,allValues) =>  {
                  if(allValues.account&&allValues.account.pay_terms)
                    return dueDateCalc(allValues.account.pay_terms,value)
                },
              }
            },
        ],

        submitBtns:[
          {label:"preview",action:"preview"},
          {label:"draft",action:"draft"},
          {label:"make",action:"save"},
        ],
        hideSubmit:true
      },



            docview:{
              label:'View Doc',
              descrption:'',
              initUrl:'/v6/doc/initviewvalues',
              load:[   ],
              decorators:[  ],
              submitBtns:[
                {label:"make",action:"save"},
              ],
            },

            docsign:{
              label:'Sign Doc',
              descrption:'',
              initUrl:'/v6/docsign/initvalues',
              load:[   ],
              decorators:[  ],
              submitBtns:[
                {label:"make",action:"save"},
                {label:"edit",action:"edit"},
              ],
            },






            account:{
              label:'Create Account',
              descrption:'',
              initUrl:'/v6/account/initvalues',
              load:[   ],
              decorators:[  ],
              submitBtns:[  ],
            },


            item:{
              label:'Create Item',
              descrption:'',
              initUrl:'/v6/item/initvalues',
              load:[   ],
              decorators:[  ],
              submitBtns:[  ],
            },

















      company:{
             label:'New Company Form',
             descrption:'',
             type:'wizard',
             actionBtns:[],

             fields: [
               { name:"wizard",label:"Company",type:"wizard",rules:[],
                  page:0,
                 payload:[

     		/*{
     			name:"companywizard.type",
     			label:"companywizard.type",
     			payload:[{name:"panel",type:"CompanyCreateFirst"}]
     		},*/
        {
     			name:"companywizard.details",
     			label:"Company.DetailsTxt",
     			payload:[
            //{name:"panel",label:"DocBlock",type:"panel",rules:[],gridOpt:{xs:12,md:6},
            //  payload:[

                {name:"name",label:"general.NameTxt",type:"string",rules:['required','string'],gridOpt:{xs:12},payload:{endAdornment:"business"}},

                {name:"phone",label:"general.PhoneTxt",type:"string",rules:['string'],gridOpt:{xs:6},payload:{endAdornment:"phone"}},
                {name:"address",label:"general.AddressTxt",type:"string",rules:['string'],gridOpt:{xs:6},payload:{endAdornment:"home"}},
                {name:"country",label:"general.CountryTxt",type:"downshift",rules:['required','string']
                ,defualt:{id:"IL",name:"Israel"}
                ,gridOpt:{xs:6},payload:{type:'search',model:'AccCountry',query:{},endAdornment:"flag"}},
                //{name:"chain_id",label:"Production Chain",type:"downshift",rules:[],payload:{}},


                {name:"logo",label:"general.LogoTxt",type:"file",rules:['image'],gridOpt:{xs:6},payload:{endAdornment:"image"}},

                {name:"fax",label:"general.FaxTxt",type:"string",rules:['string'],gridOpt:{xs:6},payload:{endAdornment:"print"}},
                {name:"city",label:"general.CityTxt",type:"string",rules:['string'],gridOpt:{xs:6},payload:{endAdornment:"location_city"}},
                {name:"zip",label:"general.ZipTxt",type:"string",rules:['string'],gridOpt:{xs:6},payload:{endAdornment:"markunread_mailbox"}},



          //    ]
          //  },


          ]

     		},

        {
          name:"companywizard.tax",
          label:"Company.TaxTxt",
          payload:[

            //{name:"panel",label:"DocBlock",type:"panel",rules:[],gridOpt:{xs:12,md:6},
            //  payload:[

                //validation if country.id=IL vatnumVal


                {name:"type",label:"Company.CompanyTypeTxt",type:"select",rules:['required','integer'],payload:{loaderType:'inline',data:[
                  {id:1,name:"Company.TYPE_REGULAR"},
                  {id:2,name:"Company.TYPE_EXEMPT"},
                  {id:3,name:"Company.TYPE_LTD"},
                  {id:4,name:"Company.TYPE_NPO"},


                ]},gridOpt:{xs:6}},

                {name:"vatnum",label:(values)=>{
                  if(values.type)
                    return "general.Vatnum"+values.type+"Txt"
                  return "general.VatnumTxt"
                },type:"string",rules:(values)=>(values.country&&values.country.id==='IL')?['isrealid','required','string']:['required','string'],gridOpt:{xs:6},payload:{endAdornment:"importcontacts"}},

                {name:"doublebook",label:"Company.DoublebookTxt",type:"switch",rules:['string'],gridOpt:{xs:6}},
                {name:"stock",label:"Company.StockManageTxt",type:"switch",rules:['string'],gridOpt:{xs:6}},

                //condition if type==1 || type==3
                {type:"condition",when:"type",condition:(value)=>(value===1 || value===3),gridOpt:{xs:6},payload:[
                  {name:"pretax",label:"Company.PreTaxTxt",type:"select",rules:['required','integer'],defualt:1,payload:{loaderType:'inline',data:[{id:1,name:"Company.TYPE_MONTHLY"},{id:2,name:"Company.TYPE_BI_MONTHLY"}]}},
                ]},

                {type:"condition",when:"type",condition:(value)=>(value===1 || value===3),gridOpt:{xs:6},payload:[
                  {name:"vattax",label:"Company.VatTaxTxt",type:"select",rules:['required','integer'],defualt:1,payload:{loaderType:'inline',data:[{id:1,name:"Company.TYPE_MONTHLY"},{id:2,name:"Company.TYPE_BI_MONTHLY"}]}},
                ]},

                {type:"condition",when:"type",condition:(value)=>(value===1 || value===3),gridOpt:{xs:6},payload:[
                  {name:"pretax_rate",label:"Company.PreTaxRateTxt",type:"string",rules:['string'],payload:{endAdornment:"%"}},
                ]}





          ]











        },

        {
          name:"companywizard.docnum",
          label:"Company.DocnumTxt",
          payload:[
            //{name:"panel",label:"DocBlock",type:"panel",rules:[],gridOpt:{xs:12,md:6},
            //  payload:[

                {name:"doctype1",label:"DoctypeName.Proforma",type:"string",rules:['string'],defualt:100000,gridOpt:{xs:6}},
                {name:"doctype2",label:"DoctypeName.DeliveryDocument",type:"string",rules:['string'],defualt:200000,gridOpt:{xs:6}},
                //npo,exampt

                {type:"condition",when:"type",condition:(value)=>(value===1 || value===3),gridOpt:{xs:6},payload:[
                  {name:"doctype3",label:"DoctypeName.Invoice",type:"string",rules:['string'],defualt:300000},
                ]},
                {type:"condition",when:"type",condition:(value)=>(value===1 || value===3),gridOpt:{xs:6},payload:[
                  {name:"doctype4",label:"DoctypeName.CreditInvoice",type:"string",rules:['string'],defualt:400000},
                ]},
                {name:"doctype5",label:"DoctypeName.ReturnDocument",type:"string",rules:['string'],defualt:500000,gridOpt:{xs:6}},
                {name:"doctype6",label:"DoctypeName.Quote",type:"string",rules:['string'],defualt:600000,gridOpt:{xs:6}},
                {name:"doctype7",label:"DoctypeName.SalesOrder",type:"string",rules:['string'],defualt:700000,gridOpt:{xs:6}},
                {name:"doctype8",label:"DoctypeName.Receipt",type:"string",rules:['string'],defualt:800000,gridOpt:{xs:6}},
                //npo,ecampt label
                {name:"doctype9",label:(values)=>{
                  if(values.type===2||values.type===4)
                    return "DoctypeName.PerformaReceipt"
                  return "DoctypeName.InvoiceReceipt"
                }


                ,type:"string",rules:['string'],defualt:900000,gridOpt:{xs:6}},
                {name:"doctype10",label:"DoctypeName.PurchaseOrder",type:"string",rules:['string'],defualt:1100000,gridOpt:{xs:6}},

                //{name:"doctype11",label:"Manual Invoice",type:"string",rules:['string'],defualt:1200000,gridOpt:{xs:6}},
                //{name:"doctype12",label:"Manual Receipt",type:"string",rules:['string'],defualt:1300000,gridOpt:{xs:6}},
                //{name:"doctype13",label:"Current Expense",type:"string",rules:['string'],defualt:1400000,gridOpt:{xs:6}},
                //{name:"doctype14",label:"Asset Expense",type:"string",rules:['string'],defualt:1500000,gridOpt:{xs:6}},
                //{name:"doctype15",label:"Warehouse Transaction",type:"string",rules:['string'],defualt:1600000,gridOpt:{xs:6}},
                //{name:"doctype16",label:"Stock entry certificate",type:"string",rules:['string'],defualt:1700000,gridOpt:{xs:6}},
                //{name:"doctype17",label:"Stock exit certificate",type:"string",rules:['string'],defualt:1800000,gridOpt:{xs:6}},
                //npo
                {type:"condition",when:"type",condition:(value)=>(value===4),gridOpt:{xs:6},payload:[
                  {name:"doctype18",label:"DoctypeName.ReceiptForDonation",type:"string",rules:['string'],defualt:1900000,gridOpt:{xs:6}},
                ]},
                //{name:"doctype19",label:"Manual Credit Invoice",type:"string",rules:['string'],defualt:2000000,gridOpt:{xs:6}},
                //{name:"doctype20",label:"Manual Invoice Receipt",type:"string",rules:['string'],defualt:2100000,gridOpt:{xs:6}},

                //{name:"doctype40",label:"Return and Credit Documenet",type:"string",rules:['string'],defualt:400,gridOpt:{xs:6}},
                //{name:"doctype65",label:"Swap Document",type:"string",rules:['string'],defualt:3000,gridOpt:{xs:6}},
                //{name:"doctype840",label:"Inventory Count",type:"string",rules:['string'],defualt:2300009,gridOpt:{xs:6}},


            //  ]
            //},

            /*
            {name:"panel",label:"DocBlock",type:"panel",rules:[],gridOpt:{xs:12,md:6},
              payload:[



                {name:"doctype9",label:"doctype9",type:"string",rules:['string']},
                {name:"doctype10",label:"doctype10",type:"string",rules:['string']},

                {name:"doctype11",label:"doctype11",type:"string",rules:['string']},
                {name:"doctype12",label:"doctype12",type:"string",rules:['string']},
                {name:"doctype13",label:"doctype13",type:"string",rules:['string']},
                {name:"doctype14",label:"doctype14",type:"string",rules:['string']},
                {name:"doctype15",label:"doctype15",type:"string",rules:['string']},
                {name:"doctype16",label:"doctype16",type:"string",rules:['string']},


              ]
            },
            */


          ]

        },

        /*{
          name:"companywizard.import",
          label:"companywizard.import",
          payload:[]

        },
        {
          name:"companywizard.upsell",
          label:"companywizard.upsell",
          payload:[]

        },*/




        /*


                     {name:"account_id",label:"Account",type:"downshift",rules:[],payload:{type:'search',model:'Account',query:{type:0},limit:50}},
                     //company
                     //oppt_account_id
                     //sub_account_id
                     {name:"vatnum",label:"VAT_No",type:"string",rules:['required']},
                     {name:"address",label:"Address",type:"string",rules:['required'],payload:{endAdornment:"home"}},
                     {name:"city",label:"City",type:"string",rules:['required']},
                     {name:"zip",label:"Zip",type:"string",rules:['required']},
                     {name:"email",label:"Email",type:"email",rules:['required'],payload:{endAdornment:"email"}},
                     {name:"phone",label:"Phone",type:"string",rules:['required'],payload:{endAdornment:"phone"}},
                     //contact

                     //country
                     //
                     {name:"updateAcc",label:"Update Account",type:"switch",rules:[],payload:{}},
*/
                 ]
               },



             ],
           },














































      ProductionStatus:{
        label:'Production Status',
        descrption:'',
        //type:requler|wizard:
        //girdParams
        //submit
        //back
        fields: [
          {name:"chain_id",label:"Production Chain",type:"downshift",rules:['required'],payload:{type:'search',model:'ProductionChain',query:{}}},
          {name:"name",label:"Name",type:"string",rules:['required']},
          {name:"num",label:"Number",type:"string",rules:['required','integer']}
        ]
      },

      ProductionChain:{
        label:'Production Chain',
        descrption:'',
        fields: [
          {name:"name",label:"Name",type:"string",rules:['required']}
        ]
      },

      ProductionStation:{
        label:'Production Station',
        descrption:'',
        fields: [
          {name:"chain_id",label:"Production Chain",type:"downshift",rules:[],payload:{type:'search',model:'ProductionChain',query:{}}},
          {name:"name",label:"Name",type:"string",rules:[]},
          {name:"input_status",label:"Input Status",type:"downshift",rules:[],payload:{type:'search',model:'ProductionStatus',query:{cahin_id:1}}},
          {name:"output_status",label:"Output Status",type:"downshift",rules:[],payload:{type:'search',model:'ProductionStatus',query:{cahin_id:1}}},

        ],
      }

    },

    tables:{


          defaulty:{
            clmns:[
              { id: "id" },
              { id: "name" }
            ]
          },


          PaymentPrint:{

            clmns:[
              { id: "id" },
              { id: "name" },

              { id: "btns",type:"action",payload:[
                //'edit',
                //'delete',
                {label:"App.UpdateTxt",action:(data,thisy)=>{
                  thisy.props.redierct('/PaymentPrint/update/'+data.id)

                }}

               ]},//btns

            ]
          },



        acctype: require( './tables/acctype').table,
        acccat: require( './tables/acccat').table,

        transaction: require( './tables/transaction').table,
        docdetailes: require( './tables/salesreport').table,

        doctype: require( './tables/doctype').table,
        itemcategory: require( './tables/itemcategory').table,
        itemunit: require( './tables/itemunit').table,


        ProductionStatus:{

          clmns:[
            { id: "num" },
            { id: "chain_id" },
            { id: "name" },
            { id: "btns",type:"action",payload:[ 'edit', 'delete', ]},//btns
          ]
        },

        ProductionChain:{

          clmns:[
            { id: "id" },
            { id: "name" },
            { id: "btns",type:"action",payload:[ 'edit', 'delete', ]},//btns
          ]
        },

        ProductionStation:{

          clmns:[
            { id: "id" },
            { id: "chain_id" },
            { id: "name" },
            { id: "input_status" },
            { id: "output_status" },



            { id: "btns",type:"action",payload:[
              'edit',
              'delete',
              {label:"App.ViewTxt",action:(data,thisy)=>{
                thisy.props.redierct('/ProductionStation/view/'+data.id+'/'+data.chain_id+'/'+data.input_status+'/'+data.output_status+'/'+data.name)

              }}

            ]},//btns
          ]
        },


        Production:{
          type:'select',
          actions:[
            {
              name:'App.BackTxt',
              action:(datas,thisy)=>{
                thisy.props.redierct("/ProductionStation/admin")
              }
            },
            {
              name:'App.PrintTxt',
              action:(datas,thisy)=>{
                //console.log(datas,model)


                var prtContent=""
                var d = new Date();


                datas.map(data=>{
                  var dd=new Date(data.extra.doc_created)
                  prtContent =prtContent+
                  "<div class='page'>"+
                   "<div><h2>הדפסת מוצר</h2></div>"+//xx
                    //"BIZ NAME"+"<br />"+//xx
                    //"BIZ ADDRESS"+"<br />"+//xx
                    //I18n.t('pos.user')+ ": " + "<br />"+//xx
                    //I18n.t('credit.terminalNo')+":"+"<br />"+//xx
                    //I18n.t('pos.paytype')+":"+type+"<br />"+//xx//
                    "<div >תאריך הזמנה: " +dd.getDate()+"/"+dd.getMonth()+"/"+dd.getFullYear()+" "+dd.getHours()+":"+dd.getMinutes()+"</div>"+
                    "<div >סימוכין: "+data.extra.doc_docnum+"</div>"+
                    "<div >שם פריט: "+data.extra.item_name+"</div>"+
                    "<div >מידה: "+data.extra.item_size+"</div>"+
                    "<div >הדפסה: "+data.extra.det_payload[6]+"</div>"+
                    "<div >תוספת: "+data.extra.det_payload[11]+"</div>"+
                    "<div >עמדת הזמנה: "+data.extra.doc_owner+"</div>"+
                    "<div>תאריך: " +d.getDate()+"/"+d.getMonth()+"/"+d.getFullYear()+" "+d.getHours()+":"+d.getMinutes()+"</div>"+
                    "<div><img src='https://dev.linet.org.il/barcode/code128?id="+data.id+"' /></div><br />"+
                    "</div>";
                  return false
                  });






                  let js="<script>document.addEventListener('DOMContentLoaded',setTimeout(function(){window.print();window.close();},500))</script>"+

                      "<style>.page{page-break-after: always;}</style>"
                  var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                  let file="<html><body style='text-align:center;direction:rtl;font-family: Rubik, Helvetica, Arial, sans-serif;'>"+prtContent+js+"</body></html>"
                  WinPrint.document.write(file);
                  WinPrint.document.close();
                  WinPrint.focus();


                  //bulk update
                  //thisy.props.ModelBulkUpdateAction("Production",[["in","id",datas.map(({id})=>id)]],{status:10})
                  thisy.props.ModelBulkUpdateAction("Production",{"id":datas.map(({id})=>id)},{status:10})
                //printLables
                //forwd status
              }
            },
            /*{
              name:'foowrwd',
              action:(data,model)=>{
                console.log(data,model)

                //printLables
                //forwd status
              }
            }*/
          ],
          clmns:[

            //{ id: "doc_id" },

            {
              id: "doc_created",
              accessor: d => d.extra.doc_created
            },
            {
              id: "doc_docnum",
              accessor: d => d.extra.doc_docnum
            },

            {
              id: "item_name",
              accessor: d => d.extra.item_name
            },
            {
              id: "item_size",
              accessor: d => d.extra.item_size
            },
            {
              id: "number",
              accessor: d => {
                if(d.extra && d.extra.det_payload &&d.extra.det_payload[6])
                 return d.extra.det_payload[6]
               return "NA"
              }
            },
            {
              id: "text",
              accessor: d => {
                if(d.extra && d.extra.det_payload &&d.extra.det_payload[11])
                 return d.extra.det_payload[11]
               return "NA"
              }
            },
            {
              id: "doc_owner",
              accessor: d => d.extra.doc_owner
            },


            {
              id: "status_name",
              accessor: d => d.extra.status_name
            },

            { id: "btns",type:"action",payload:[

              {label:"Move",action:(data,thisy)=>{
                thisy.props.ModelBulkUpdateAction("Production",{"id":[data.id]},{status:thisy.props.query.output})

              }}

            ]},//btns

            //{ id: "line" },


            //checkbox
          ]
        },



          BalanceGroup:{
            clmns:[
              { id: "id" },
              { id: "name" },
              { id: "balance_group" },
              { id: "group_character" },
              { id: "proloss" },
              { id: "btns",type:"action",payload:[ 'edit', 'delete' ]},//btns

            ]
          },

          BalanceCode:{
            clmns:[
              { id: "id" },
              { id: "name" },
              { id: "order" },
              { id: "balance_item_id" },
              { id: "btns",type:"action",payload:[ 'edit', 'delete' ]},//btns

            ]
          },

          BalanceItem:{
            clmns:[
              { id: "id" },
              { id: "name" },
              { id: "group_id" },
              { id: "btns",type:"action",payload:[ 'edit', 'delete' ]},//btns

            ]
          },

          PosState:{
            create:false,
            clmns:[
              {
                id: "type",
                type: 'translateResolver',
              },
              {
                id: "pos_id",
                type: 'modelResolver',
                resolveModel:'Pos',
                resolveAttribute:'name',
                //accessor: (d) => <ResolverField id={d.pos_id} promise={PosRequester} callback={PosResolver} />

              },
              {
                id: "user_id",
                type: 'modelResolver',
                resolveModel:'User',
                resolveAttribute:'username',
                //accessor: (d) => <ResolverField id={d.user_id} promise={UserRequester} callback={UserResolver} />
              },
              { id: "created", type:'datetime' },

              { id: "creditSumWas" },
              { id: "creditSumCalc" },
              { id: "cheqCountWas" },
              { id: "cheqCountCalc" },
              { id: "cheqSumWas" },
              { id: "cheqSumCalc" },
              { id: "creditSumWas" },
              { id: "cashSumCalc" },
              { id: "cashSumWas" },//updated
            ]

          },
          Account:{
            clmns:[
              { id: "id" },
              { id: "name", editble:true },
              { id: "type",
                editble:true,//need lookup??
                type:"select",
              },
              { id: "vatnum", editble:true },
              { id: "phone", editble:true },
              { id: "email", editble:true },
              { id: "address", editble:true },
              { id: "city", editble:true },
              { id: "zip", editble:true },
              {
                id: "cat_id",
                editble:true,//need lookup
                type:"select",
              },
              { id: "src_tax", editble:true },
              {
                id: "id6111",
                editble:true,//need lookup
                type:"select",
              },
              { id: "bank_acc", editble:true },
              { id: "firstname", editble:true },
              { id: "lastname", editble:true },
              {
                id: "parent_account_id",
                editble:true,//need lookup
                type:"select",
              },
              { id: "cellular", editble:true },
              { id: "balance" },
              { id: "fax", editble:true },
              { id: "web", editble:true },
              { id: "department", editble:true },
              { id: "created", type:'date' },
              { id: "modified", type:'date' },
              { id: "iban", editble:true },
              {
                id: "pay_terms",
                editble:true,
                type:"select",
              },
              { id: "owner", editble:true },
              //eavs!
            ]
          },

          Item:{
            clmns:[
              { id: "id", editble:true },
              { id: "sku", editble:true },
              { id: "name", editble:true },
              { id: "saleprice", editble:true },
              { id: "vatIn", editble:true, type:'boolean' },
              {
                id: "currency_id",
                editble:true,//needl lookup
              },
              { id: "extcatnum", editble:true },
              { id: "active", type: "boolean", editble:true },
              { id: "manufacturer", editble:true },
              { id: "unit_id", type:"select",
                editble:true,//needl lookup
              },
              {
                id: "stockType",
                type:"select",
                editble:true,//needl lookup
              },
              { id: "purchaseprice", editble:true },
              {
                id: "purchase_currency_id",
                type:"select",
                editble:true,//needl lookup
              },
              { id: "profit", editble:true },
              { id: "description", editble:true },
              { id: "itemVatCat_id",
                type:"select",
                //get list..
                editble:true,//needl lookup
              },
              {
                id: "income_acc",
                type:"select",
                //get list..
                editble:true,//needl lookup
              },
              {
                id: "isProduct",
                type:"select",
                editble:true,//needl lookup
              },
              {
                id: "pic",
                editble:true,
              },
              {
                id: "category_id",
                editble:true,
                type:"select",
                //get list..
                accessor: d => d.category_id//show pic!
              },
              //eavs
            ]
          },
          Doc:{
            clmns:[
              {
                id: "doctype",
                type:"select",
                //get list..
                opt:[{id:1 ,name:"Performa",},{id:2 ,name:"Deliviry",},{id:3 ,name:"Invoice"},{id:4 ,name:"Credit"}]
              },
              { id: "docnum" },
              { id: "company" },
              { id: "id" },
              { type:'date', id: "issue_date" },
              { type:'date', id: "due_date" },
              { id: "vatnum" },
              { id: "total" },
              { id: "novat_total" },
              { id: "sub_total" },
              { id: "vat" },
              { id: "phone" },
              { id: "address" },
              { id: "city" },
              { id: "zip" },
              { id: "email" },
              {
                id: "language",
                type:"select",
                opt:[{id:"he_il" ,name:"Hebrew",},{id:"en_us" ,name:"Englis"}]
              },
              {
                id: "currency_id",
                type:"select",
                opt:[{id:"ILS" ,name:"Isreali New"},{id:"USD" ,name:"US Doller"},{id:"EUR" ,name:"Euro"}]
              },
              { id: "currency_rate" },
              /*{
                Header: <Translate value="App.searchAccount_cat_idTxt"/>,
                id: "searchAccount_cat_id",
                accessor: d => d.searchAccount_cat_id//'account.category.name'
              },*/
              { id: "description" },
              { id: "comments" },
              { id: "status" },
              { id: "refnum_ext" },
              { id: "refnum" },
              { id: "refstatus" },
              { id: "oppt_account_id" },
              { id: "owner" },

              /*
              [
                'attribute' => 'doc_value',
                'value' => function($data) {
                  return \app\helpers\Linet3Helper::asCur(($data->sub_total+$data->novat_total)*$data->currency_rate);
                },
                'format' => 'raw',
                'visible' => false,
              ],
              'due_date',
              'sub_total',
              'novat_total',
              'vat',
              'src_tax',
              '',
              'printed',
              'comments',
              'owner',
              */
            ]
          },



    },

    views:{  }
},action){

  switch(action.type){
    case "select-company":
      state={...state,
        company_id:action.payload,
      }
      break;
    case "load-company":
      state={...state,
        main_menu:action.payload.main_menu,
        conf_menu:action.payload.conf_menu,
        settings:action.payload.settings,
        user:action.payload.user,
        //user_id:action.payload.user.id,
        //workerId: action.data.id,
      }
      break;
    case "AAA":
      state={...state,
        //workerId: action.data.id,
      }
      break;
    default:
      break;
  }
  return state;
}

export default appReducer;
