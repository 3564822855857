import {  compose,combineReducers, createStore, applyMiddleware } from 'redux';

import logger from "redux-logger";
import thunk from "redux-thunk";

import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';

import { setLocale, syncTranslationWithStore, i18nReducer } from 'react-redux-i18n';

import appReducer from './reducers/appReducer.js';
import dataReducer from './reducers/dataReducer.js';

offlineConfig.returnPromises= true
offlineConfig.persistOptions={
  key: 'root',

  //storage: storage,
  blacklist: ['AppReducer','i18n'] // navigation will not be persisted
}


const reducers = combineReducers({
  AppReducer: appReducer,
  DataReducer: dataReducer,
  i18n: i18nReducer,
});



const store = createStore(
  reducers,
  {},
  compose(
    applyMiddleware(thunk,logger),
    offline(offlineConfig)
  )
);

syncTranslationWithStore(store)
//store.dispatch(loadTranslations(translationsObject));
store.dispatch(setLocale('he_il'));

export default store;
