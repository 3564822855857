
const dataReducer = function(state = {
    //punched:false,
    models:{},

    updating:{},
    updated:{},

    creating:{},
    created:{},
},action){

  switch(action.type){


    case "DATA_SET_ATTR":
      const {attr,value}=action.payload
      //mybe more verstile
      return {...state, [attr]: value }

    case "CREATE_MODEL":
      var cid= parseInt(state.creating[action.payload.model])+1;
      cid=isNaN(cid)?1:cid
      return {...state,
        creating:{...state.creating,[action.payload.model]: cid},
        created:{...state.created,[action.payload.model]: false},
      }
    case "CREATE_MODEL_COMMIT":
      var ccid= parseInt(state.creating[action.meta.model])-1;
      ccid=isNaN(ccid)?0:ccid
      return {...state,
        creating:{...state.creating,[action.meta.model]: ccid},
        created:{...state.created,[action.meta.model]: ccid===0},
      }
    case "UPDATE_MODEL":
      var uid= parseInt(state.updating[action.payload.model])+1;
      uid=isNaN(uid)?1:uid
      return {...state,
        updating:{...state.updating,[action.payload.model]: uid},
        updated:{...state.updated,[action.payload.model]: false},
      }
      //return {...state, updatingModel: action.payload.model,updatedModel:false, }

    case "UPDATE_MODEL_COMMIT":
      //return {...state, updatingModel: false,updatedModel:action.meta.model }
      var number= parseInt(state.updating[action.meta.model])-1;
      number=isNaN(number)?0:number
      return {...state,
        updating:{...state.updating,[action.meta.model]: number},
        updated:{...state.updated,[action.meta.model]: number===0},
      }


    case "BULK_UPDATE_MODEL":
      //return {...state, updatingModel: false,updatedModel:action.meta.model }

      let modelName=action.payload.model
      let ids=action.payload.query.id

      //let data=action.payload.data

      let models=state.models[modelName].slice()
        .filter(
          (orgModel,sindex)=>{

            var noIndex=true
                    ids.map(
                      (updateId)=> {
                        if(updateId === orgModel.id)
                          //orgModel={...orgModel,...data}
                          noIndex=false
                        return true
                      }
                    )
                  return noIndex
          }

        )

        console.log(models)
      return {...state,
        models:{...state.models,[modelName]: models},

      }

    case "FETCH_MODELS":
      return {...state,
        //precived performens!
        //models:{...state.models,[action.payload.model]: []  }
       }
    case "FETCH_MODELS_COMMIT":
      return {...state,
          models:{...state.models,[action.meta.model]: action.payload   }
         }
      //state.models=models

    case "FETCH_ACCOUNTS_LOGIN_RECIVE":
      state={...state,
        workerId: action.data.id,
        workerMsg:action.data.msg,
        workerName:action.data.name,
        workerLogged:action.data.logged,
        workerState:action.data.state,
      }
      break;

    default:
        break;

  }

  return state;
}
export default dataReducer;
