export const table =  {
  label:"Account Types",
  exportOpt:{
    sheetName:"Account Types",
    fileName:"Account Types"
  },

  clmns:[
    {
      'id': 'id',
      'visible':true,
      'type':'number'
    },
    {
      'id': 'name',
      'visible':true,
    },
    {
      'id': 'desc',
      'visible':true,
    },
    {
      'id': 'hash_id',
      'visible':true,
    },
    {
      'id': 'balance_id',
      'visible':true,
    },
    {
      'id': 'last_id',
      'visible':true,
    },

  ]

}
