import {cValue} from './cValue.js';
import {tValue} from './tValue.js';

import {round} from './round.js';
export const aValue = (sum,obj, bridge)=> {

  if (typeof bridge === 'undefined')
    bridge= -1;

  if (obj._currency_rate === 0) {
    return 0;
  }

  if (obj._currency_rate > 1) {
    bridge*=-1;
  }

  if (bridge !== -1) {
    sum = cValue(sum,obj);
  } else {
    sum = tValue(sum,obj);
  }
  return round(sum); //$sum;//
}
